<template>
  <GeneralButton
    :label="props.label"
    :icon="props.icon"
    :disabled="props.disabled"
    :loading="props.loading"
    class="w-full text-sm font-semibold transition-colors rounded text-dark-500 disabled:bg-gray-300 disabled:hover:bg-gray-300 disabled:cursor-default active:bg-primary-700 disabled:text-gray-400 bg-button-primary-500 hover:bg-primary-500 focus:outline-none focus-visible:ring-2 focus-visible:ring-primary-600"
    :class="{ 'py-3': !props.loading, ' py-[10px]': props.loading }"
  />
</template>
<script setup>
import GeneralButton from '@/Redesign/Components/Buttons/GeneralButton.vue';

const props = defineProps({
  label: {
    type: String,
    default: '',
  },
  icon: {
    type: Object,
    default: () => ({
      name: '',
      class: 'mr-2',
      size: 24,
      fill: false,
      position: 'left',
    }),
  },
  disabled: {
    type: Boolean,
    default: false,
  },
  loading: {
    type: Boolean,
    default: false,
  },
});
</script>

<style scoped lang="scss"></style>
